<template>
  <div class="row waybill-shared-index-master">
    <div class="col-md-12">
      <span class="bold">
        <span>
        <i class="fas fa-download float-right m-3 mt-0" role="button"
            @click="getItems"></i>
        </span>
      </span>
    </div>

    <div class="col-md-12">

      <div class="w-100 d-flex justify-content-center">

        <div class="w-lg-75 w-md-100 w-sm-100 w-100 row text-center font-weight-boldest text-md">
          <div :class="{'bg-primary text-white': currentActiveTab === 1}"
               class="col-6 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-right-0 border-radius-left-bottom-10px border-radius-left-top-10px"
               @click="currentActiveTab = 1">
            {{ $t('general.waybill_actions') }}
          </div>
          <div :class="{'bg-primary text-white': currentActiveTab === 2}"
               class="col-6 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-radius-right-bottom-10px border-radius-right-top-10px"
               @click="currentActiveTab = 2">
            {{ $t('general.invoice_actions') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
          <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
           <h3>İrsaliye Giriş İşlemleri</h3>
        </span>

      <dashboard-box title="İrsaliye Giriş İşlemleri">
        <template v-slot:preview>
          <div class="waybill-shared-table">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="formattedWaybill"
              :loading="loadingWaybillIncoming"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
              @rowClicked="onRowClicked"
          ></data-table>
          </div>

        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Çıkış İşlemleri
        </h3>
      </span>

      <dashboard-box title="İrsaliye Çıkış İşlemleri">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="formattedWaybillExit"
              :loading="loadingWaybillOutgoing"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
              @rowClicked="onRowClicked"
          ></data-table>
        </div>
        </template>
      </dashboard-box>
    </div>

  <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Üretim Çıkış İade İşlemleri
        </h3>
      </span>

      <dashboard-box title="İrsaliye Çıkış İşlemleri">
        <template v-slot:preview>
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFields"
              :hover="false"
              :items="formattedWaybillForReturn"
              :loading="loadingWaybillIncoming"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
              @rowClicked="onRowClicked"
          ></data-table>
        </template>
      </dashboard-box>
    </div>


    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Giriş Toplamı
        </h3>
      </span>

      <dashboard-box title="İrsaliye Giriş Toplamları">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotal"
              :hover="false"
              :items="formattedWaybillEntranceTotals "
              :loading="loadingWaybillTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
        </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Çıkış Toplamları
        </h3>
      </span>

      <dashboard-box title="İrsaliye Giriş Toplamları">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotal"
              :hover="false"
              :items="formattedWaybillExitTotals"
              :loading="loadingWaybillTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>


    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Üretim Çıkış İşlemleri
        </h3>
      </span>

      <dashboard-box title="İrsaliye Çıkış İşlemleri">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotal"
              :hover="false"
              :items="formattedWaybillExitTotalsForProduction"
              :loading="loadingWaybillOutgoing"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
              @rowClicked="onRowClicked"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 1" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Giriş Çıkış Toplamı
        </h3>
      </span>

      <dashboard-box title="İrsaliye Giriş Çıkış Toplamı">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotal"
              :hover="false"
              :items="formattedWaybillTotals"
              :loading="loadingWaybillTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>


    <div v-if="false" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          İrsaliye Üretim Çıkış Toplamı
        </h3>
      </span>

      <dashboard-box title="İrsaliye Giriş Toplamları">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotal"
              :hover="false"
              :items="formattedWaybillTotalsForProduction "
              :loading="loadingWaybillTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 2" class="col-md-12">
      <span class="bold">
         <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          <h3>Fatura İşlemleri</h3>
      </span>
      <dashboard-box :title="$t('waybill.waybill_list')">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields="tableFieldsInvoice"
              :hover="false"
              :items="formattedInvoices"
              :no-border-collapse="true"
              :outlined="false"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
              @rowClicked="onRowClickedInvoice"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 2" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          Fatura Giriş Toplamı
        </h3>
      </span>

      <dashboard-box title="Fatura Giriş Toplamı">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotalForInvoice"
              :hover="false"
              :items="formattedInvoicesForEntranceTotal "
              :loading="loadingInvoiceTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 2" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          Fatura Çıkış Toplamları
        </h3>
      </span>

      <dashboard-box title="Fatura Çıkış Toplamları">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotalForInvoice"
              :hover="false"
              :items="formattedInvoicesForExitTotal"
              :loading="loadingInvoiceTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <div v-if="+currentActiveTab === 2" class="col-md-12">
        <span class="bold">
        <h3> <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)"></span>
          Fatura Giriş Çıkış Toplamı
        </h3>
      </span>

      <dashboard-box title="Fatura Giriş Çıkış Toplamı">
        <template v-slot:preview>
          <div class="waybill-shared-table">
          <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="tableFieldsTotalForInvoice"
              :hover="false"
              :items="formattedInvoicesForTotal"
              :loading="loadingInvoiceTotal"
              :no-border-collapse="true"
              :outlined="false"
              :per-page="0"
              :striped="false"
              class="narrow-table"
              tableVariant="''"
          ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations} from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import {GET_ITEMS} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";

import SecureLS from "secure-ls";

const ls = new SecureLS({encodingType: 'aes', isCompression: true});

export default {
  name: "WaybillIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    DateFilter
  },
  computed: {
    filters() {
      return {
        page: this.page,
        per_page: this.perPage,
        waybillable_type: this.waybillable_type,
        waybillable_id: this.waybillable_id,
        only_fabrics: this.onlyFabrics,
        only_expenses: this.onlyExpenses,
        only_accessories: this.onlyAccessories,
        order_product_id: this.order_product_id,
        order_size_and_color_id: this.order_size_and_color_id,
        invoice_status_id: 18,
        ignore_calculation: 1
      };
    },
    formattedWaybill() {
      let temp = [];
      if (!this.waybills) return temp;

      this.waybills.forEach(subItem => {
        let item = subItem.waybill;
        if (+item.transaction_type === 1 && +item.waybill_type_id === 1) {
          let unit = subItem.unit ? subItem.unit.code : '';
          let unitPrice = subItem.price_converted / subItem.amount
          temp.push({
            'waybillable_id': subItem.waybillable_id,
            'waybillable_type': subItem.waybillable_type,
            'instructable_type': subItem.instructable_type,
            'instructable_id': subItem.instructable_id,
            'order_product_id': subItem.order_product_id,
            'id': item.id,
            'waybill_action_type': item.transaction_type,
            'waybill_date': moment(item.waybill_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'created_date': moment(item.transaction_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'company_name': item.supplier_company.name,
            'waybill_type': item.waybill_type.translations[0].name,
            'instruction_type': subItem.instruction_type.translations[0].name,
            'document_no': item.document_number,
            'net_amount': Number(subItem.net_amount) + ' ' + unit,
            'gross_amount': Number(subItem.gross_amount) + ' ' + unit,
            'order_size_and_color':subItem.order_size_and_color? subItem.order_size_and_color.full_name: subItem.product_name
          })
        }
      })
      return temp;
    },
    formattedWaybillForReturn() {
      let temp = [];
      if (!this.waybills) return temp;

      this.waybills.forEach(subItem => {
        let item = subItem.waybill;
        if (+item.transaction_type === 1 && +item.waybill_type_id === 3) {
          let unit = subItem.unit ? subItem.unit.code : '';
          temp.push({
            'waybillable_id': subItem.waybillable_id,
            'waybillable_type': subItem.waybillable_type,
            'instructable_type': subItem.instructable_type,
            'instructable_id': subItem.instructable_id,
            'order_product_id': subItem.order_product_id,
            'id': item.id,
            'waybill_action_type': item.transaction_type,
            'waybill_date': moment(item.waybill_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'created_date': moment(item.transaction_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'company_name': item.supplier_company.name,
            'waybill_type': item.waybill_type.translations[0].name,
            'instruction_type': subItem.instruction_type.translations[0].name,
            'document_no': item.document_number,
            'net_amount': Number(subItem.net_amount) + ' ' + unit,
            'gross_amount': Number(subItem.gross_amount) + ' ' + unit,
          })
        }
      })
      return temp;
    },
    formattedWaybillTotals() {
      let temp = [];
      if (!this.waybillTotals) return temp;
      this.waybillTotals.forEach(item => {
        temp.push({
          'instruction_type': item.name,
          'id': item.id,
          'net_amount': `${item.amount} ${item.unit}`,
          'gross_amount': `${item.gross_amount} ${item.unit}`
        })
      });

      return temp;
    },
    formattedWaybillTotalsForProduction() {
      let temp = [];
      if (!this.waybillTotalsForProduction) return temp;
      this.waybillTotalsForProduction.forEach(item => {
        if (+item.amount !== 0 && +item.gross_amount !== 0) {
          temp.push({
            'instruction_type': item.name,
            'id': item.id,
            'net_amount': `${item.amount} ${item.unit}`,
            'gross_amount': `${item.gross_amount} ${item.unit}`
          })
        }
      });

      return temp;
    },
    formattedWaybillExitTotalsForProduction() {
      let temp = [];
      if (!this.waybillExitTotalsForProduction) return temp;
      this.waybillExitTotalsForProduction.forEach(item => {
        if (+item.amount !== 0 && +item.gross_amount !== 0) {
          temp.push({
            'instruction_type': item.name,
            'id': item.id,
            'net_amount': `${item.amount} ${item.unit}`,
            'gross_amount': `${item.gross_amount} ${item.unit}`
          })
        }
      });

      return temp;
    },
    formattedWaybillExitTotals() {
      let temp = [];
      if (!this.waybillExitTotals) return temp;
      this.waybillExitTotals.forEach(item => {
        if (+item.amount !== 0 && +item.gross_amount !== 0) {
          temp.push({
            'instruction_type': item.name,
            'id': item.id,
            'net_amount': `${item.amount} ${item.unit}`,
            'gross_amount': `${item.gross_amount} ${item.unit}`
          })
        }
      });

      return temp;
    },
    formattedWaybillEntranceTotals() {
      let temp = [];
      if (!this.waybillEntranceTotals) return temp;
      this.waybillEntranceTotals.forEach(item => {
        if (+item.amount !== 0 && +item.gross_amount !== 0) {
          temp.push({
            'instruction_type': item.name,
            'id': item.id,
            'net_amount': `${item.amount} ${item.unit}`,
            'gross_amount': `${item.gross_amount} ${item.unit}`
          })
        }
      });

      return temp;
    },
    formattedWaybillExit() {
      let temp = [];
      if (!this.waybills) return temp;
      this.waybills.forEach(subItem => {
        let item = subItem.waybill;
        if (+item.transaction_type === 0) {
          let unit = subItem.unit ? subItem.unit.code : '';

          temp.push({
            'waybillable_id': subItem.waybillable_id,
            'waybillable_type': subItem.waybillable_type,
            'instructable_type': subItem.instructable_type,
            'instructable_id': subItem.instructable_id,
            'order_product_id': subItem.order_product_id,
            'id': item.id,
            'waybill_action_type': item.transaction_type,
            'waybill_date': moment(item.waybill_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'created_date': moment(item.transaction_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
            'company_name': item.supplier_company.name,
            'order_size_and_color':subItem.order_size_and_color? subItem.order_size_and_color.full_name: subItem.product_name,
            'waybill_type': item.waybill_type.translations[0].name,
            'instruction_type': subItem.instruction_type.translations[0].name,
            'document_no': item.document_number,
            'net_amount': Number(subItem.net_amount) + ' ' + unit,
            'gross_amount': Number(subItem.gross_amount) + ' ' + unit,
          })
        }
      })
      return temp;
    },
    formattedInvoices() {
      let temp = [];
      if (!this.invoices) return temp;
      this.invoices.forEach(subItem => {
        let item = subItem.invoice;
        let vatPrice = Number(subItem.vat_price_converted);
        let unit = subItem.unit ? subItem.unit.code : '';
        temp.push({
          'id': item.id,
          'waybill_action_type': +item.is_service === 0 ? 1 : 0,
          'created_date': moment(item.invoice_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'company_name': item.supplier_company.name,
          'order_size_and_color':subItem.order_size_and_color? subItem.order_size_and_color.full_name: '',
          'waybill_type': item.invoice_category.translations[0].name,
          'instruction_type': subItem.instruction_type.translations[0].name,
          'document_no': item.invoice_no,
          'amount': `${subItem.amount} ${unit}`,
          'vat_price': vatPrice + ` ${this.defaultCurrency}`,
          'total_price': subItem.price_converted + ` ${this.defaultCurrency}`,
          'unit_price': unitPrice.toFixed(4)
        })
      })
      return temp;
    },
    formattedInvoicesForTotal() {
      let temp = [];
      if (!this.invoiceTotals) return temp;
      let invoiceTotal = 0;
      this.invoiceTotals.forEach(item => {
        invoiceTotal += Number(item.price);
        temp.push({
          'instruction_type': item.name,
          'id': item.id,
          'net_amount': `${item.amount} ${item.unit}`,
          'total_price': `${item.price} ${this.defaultCurrency}`
        })
      });

      temp.push({
        'instruction_type': "",
        'id': "",
        'net_amount': "",
        'total_price': ""
      })

      temp.push({
        'instruction_type': "Fatura Toplamı",
        'id': 0,
        'net_amount': "-",
        'total_price': `${invoiceTotal} ${this.defaultCurrency}`
      })

      return temp;
    },

    formattedInvoicesForEntranceTotal() {
      let temp = [];
      if (!this.invoiceEntranceTotals) return temp;
      let invoiceTotal = 0;
      this.invoiceEntranceTotals.forEach(item => {
        invoiceTotal += Number(item.price);
        temp.push({
          'instruction_type': item.name,
          'id': item.id,
          'net_amount': `${item.amount} ${item.unit}`,
          'total_price': `${item.price} ${this.defaultCurrency}`
        })
      });

      temp.push({
        'instruction_type': "",
        'id': "",
        'net_amount': "",
        'total_price': ""
      })

      temp.push({
        'instruction_type': "Fatura Toplamı",
        'id': 0,
        'net_amount': "-",
        'total_price': `${invoiceTotal} ${this.defaultCurrency}`
      })

      return temp;
    },
    formattedInvoicesForExitTotal() {
      let temp = [];
      if (!this.invoiceExitTotals) return temp;
      let invoiceTotal = 0;
      this.invoiceExitTotals.forEach(item => {
        invoiceTotal += Number(item.price);
        temp.push({
          'instruction_type': item.name,
          'id': item.id,
          'net_amount': `${item.amount} ${item.unit}`,
          'total_price': `${item.price} ${this.defaultCurrency}`
        })
      });

      temp.push({
        'instruction_type': "",
        'id': "",
        'net_amount': "",
        'total_price': ""
      })

      temp.push({
        'instruction_type': "Fatura Toplamı",
        'id': 0,
        'net_amount': "-",
        'total_price': `${invoiceTotal} ${this.defaultCurrency}`
      })

      return temp;
    },
    tableFieldsTotal() {

      if (this.onlyFabrics) {
        return [
          {
            'key': 'instruction_type',
            'label': this.$t('order.transaction_type'),
            'class': "text-center border border-top-0 border-left-0 text-nowrap",
            'tdClass': "cursor-pointer",
          },
          {
            'key': 'gross_amount',
            'label': this.$t('order.gross_amount'),
            'class': "text-center border border-top-0 border-left-0 text-nowrap",
            'tdClass': "cursor-pointer",
          },
          {
            'key': 'net_amount',
            'label': this.$t('order.net_amount'),
            'class': "text-center border border-top-0 border-left-0 text-nowrap",
            'tdClass': "cursor-pointer",
          }
        ]
      }

      return [
        {
          'key': 'instruction_type',
          'label': this.$t('order.transaction_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'net_amount',
          'label': this.$t('order.net_amount'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        }
      ]

    },
    tableFieldsTotalForInvoice() {
      return [
        {
          'key': 'instruction_type',
          'label': this.$t('order.transaction_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'net_amount',
          'label': this.$t('order.net_amount'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'total_price',
          'label': this.$t('order.total_price'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        }
      ]

    },
    tableFields() {
      return [
        {
          'key': 'waybill_action_type',
          'label': "",
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'document_no',
          'label': this.$t('order.document_no'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'waybill_date',
          'label': this.$t('waybill.waybill_date'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'created_date',
          'label': this.$t('order.action_date'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'company_name',
          'label': this.$t('order.supplier_company'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'order_size_and_color',
          'label': this.$t('order.color'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'waybill_type',
          'label': this.$t('order.action_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'instruction_type',
          'label': this.$t('order.transaction_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'gross_amount',
          'label': this.$t('order.gross_amount'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'net_amount',
          'label': this.$t('order.net_amount'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        }
      ]
    },
    tableFieldsInvoice() {
      return [
        {
          'key': 'waybill_action_type',
          'label': "",
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'document_no',
          'label': this.$t('order.document_no'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'created_date',
          'label': this.$t('order.action_date'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'company_name',
          'label': this.$t('order.supplier_company'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'order_size_and_color',
          'label': this.$t('order.color'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'waybill_type',
          'label': this.$t('order.action_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'instruction_type',
          'label': this.$t('order.transaction_type'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'unit_price',
          'label': this.$t('invoice.unit_price'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'amount',
          'label': this.$t('order.amount'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'vat_price',
          'label': this.$t('order.vat_price'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        },
        {
          'key': 'total_price',
          'label': this.$t('order.total_price'),
          'class': "text-center border border-top-0 border-left-0 text-nowrap",
          'tdClass': "cursor-pointer",
        }
      ]
    }
  },
  data() {
    return {
      // Filters & pagination
      waybillable_id: null,
      waybillable_type: null,
      order_product_id: null,
      editable: true,
      defaultCurrency: "TL",
      defaultAmount: "KG",
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      search: null,
      paginate: [],
      page: 1,
      perPage: 10000,
      currentWaybillType: 1,
      selectedWaybillType: null,
      waybills: null,
      waybillTotals: null,
      waybillExitTotalsForProduction: null,
      waybillTotalsForProduction: null,
      invoiceTotals: null,
      invoiceEntranceTotals: null,
      invoiceExitTotals: null,
      invoiceTotal: 0,
      waybillExitTotals: null,
      waybillEntranceTotals: null,
      invoices: null,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],

      storeData: [],
      waybillTypes: {
        "0": "Çıkış",
        "1": "Giriş",
      },
      storeUrl: 'api/stores',
      waybillListUrl: 'api/waybill-transactions',
      waybillTotalUrl: 'api/waybills/totals',
      invoiceTotalUrl: 'api/invoice/totals',
      invoiceListUrl: 'api/invoice-transactions',
      onlyExpenses: 0,
      onlyFabrics: 0,
      onlyAccessories: 0,
      order_size_and_color_id: null,
      loading: false,
      loadingWaybillIncoming: false,
      loadingWaybillOutgoing: false,
      loadingWaybillTotal: false,
      loadingInvoiceTotal: false,
      loadingInvoice: false,
      currentActiveTab: 1,
      product_name: '',
    };
  },
  methods: {
    ...mapMutations({}),
    getItems() {
      this.loading = true;
      this.loadingWaybillOutgoing = true;
      this.loadingWaybillIncoming = true;
      this.loadingWaybillTotal = true;
      this.loadingInvoice = true;
      this.loadingInvoiceTotal = true;
      this.onHitBottom();
      this.onHitBottomInvoice();
      this.getWaybillTotals();
      this.getInvoiceTotals();
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onHitBottom() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.waybillListUrl,
        filters: this.filters,
      }).then(result => {
        if (result.status) {
          self.waybills = result.data ? result.data.data : [];
          this.loading = false;
        }
        this.loadingWaybillOutgoing = false;
        this.loadingWaybillIncoming = false;
      })
    },
    getWaybillTotals() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.waybillTotalUrl,
        filters: this.filters,
      }).then(result => {
        if (result.status) {
          self.waybillTotals = result.data.total;
          self.waybillEntranceTotals = result.data.entranceTotal;
          self.waybillExitTotals = result.data.exitTotal;
          self.waybillExitTotalsForProduction = result.data.exitTotalForProduction;
          self.waybillTotalsForProduction = result.data.totalForProduction;
          this.loading = false;
        }
        this.loadingWaybillTotal = false;
      })
    },
    getInvoiceTotals() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.invoiceTotalUrl,
        filters: this.filters,
      }).then(result => {
        if (result.status) {
          self.invoiceTotals = result.data.total;
          self.invoiceEntranceTotals = result.data.entranceTotal;
          self.invoiceExitTotals = result.data.exitTotal;
          this.loading = false;
        }
        this.loadingInvoiceTotal = false;
      })
    },
    onHitBottomInvoice() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: this.invoiceListUrl,
        filters: this.filters,
      }).then(result => {
        if (result.status) {
          self.invoices = result.data ? result.data.data : [];
          this.loading = false;
        }
        this.loadingInvoice = false;
      })
    },
    resetFilters() {
      this.startDate = moment().subtract(30, 'd');
      this.endDate = moment().add(1, 'd');
      this.search = null;
      this.selectedWaybillType = null;
    },
    onRowClicked(item, index, event) {
      this.$router.push({name: 'waybill.detail', params: {id: item.id,}});
    },
    onRowClickedInvoice(item, index, event) {
      if (item.waybill_action_type) {
        this.$router.push({name: 'invoice.details', params: {id: item.id,}});
      } else {
        this.$router.push({name: 'invoice.outgoing_details', params: {id: item.id,}});
      }
    },
  },
  mounted() {
    if (!this.isUserGranted('Waybill', ['viewAny'])) {
      return false;
    }

    let id = this.$route.params.id;
    let type = this.$route.params.type;
    this.page = 1;

    if (id) {
      this.waybillable_id = id.split("-")[1];
      this.waybillable_type = id.split("-")[0] == 'O' ? 'App\\Models\\Order' : 'App\\Models\\Sample';
      this.order_size_and_color_id = this.$route.params.sizeAndColorId;

      if (this.$route.params.name) {
        this.product_name = this.$route.params.name;
        ls.set('product_name', this.product_name);
      } else {
        this.product_name = ls.get('product_name');
      }
    }

    if (+type === 1 || +type === 3) {
      this.onlyFabrics = 1
    }
    if (+type === 2) {
      this.onlyExpenses = 1
    }

    if (+type === 4) {
      this.onlyAccessories = 1
    }
    this.order_product_id = this.$route.params.detailId;
    this.waybills = null;
    this.invoices = null;

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("waybill.waybills")}
    ]);

    this.getItems();
  }
}
</script>

<style>
*:focus {
  outline: none;
}
</style>
